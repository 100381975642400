<template>
<div class="config-device-container">
  <div class="config-tree-area">
    <GroupTree />
  </div>
  <div class="device-data-area">
    <div class="query-data-area">
      <span class="ml10">{{showLang('com.tab.title')}}：</span>
      <Input type="text" v-model="filter.name" clearable :placeholder="showLang('com.tab.title')" style="width: 140px; margin-right: 10px;"></Input>
      {{showLang('com.export.cn.connid')}}：
      <Input type="text" v-model="filter.code" clearable :placeholder="showLang('com.export.cn.connid')" style="width: 140px; margin-right: 10px;"></Input>
      {{ showLang('com.date.start') }}：
      <DatePicker v-model="filter.start" type="date" format="yyyy-MM-dd" style="margin-right: 10px; width: 120px;"></DatePicker>
      {{ showLang('com.date.end') }}：
      <DatePicker v-model="filter.end" type="date" format="yyyy-MM-dd" style="margin-right: 10px; width: 120px;"></DatePicker>
      <Button v-if="funCodes(7010)" size="default" type="primary" @click="search" style="margin-right: 10px;">{{showLang('com.op.query')}}</Button>
      <!-- <Button type="success" :loading="exportLoading" @click="exportData" style="margin-right: 10px;">{{showLang('com.op.export')}}</Button> -->
    </div>
    <div class="alarm-data-area" ref="table1">
      <vxe-table :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow keep-source
        ref="chkTable" :key="dataRefresh" @checkbox-change="selectChangeEvent" :data="list" height="auto" stripe
        :row-config="{isHover: true, height: rowHeight}" :checkbox-config="{checkField: 'checked',highlight: true}" @checkbox-all="selectAllEvent"
        :seq-config="{startIndex: (filter.index-1)*filter.size}" row-id="id">
        <vxe-column type="seq" width="60" :title="showLang('com.export.cn.seq')" fixed="left"></vxe-column>
        <vxe-column width="50" type="checkbox" field="checkbox" fixed="left"></vxe-column>
        <vxe-column field="lampName" :title="showLang('com.tab.title')" width="150" fixed="left" header-align="center"></vxe-column>
        <vxe-column field="lampCode" :title="showLang('com.export.cn.connid')" width="150" header-align="center"></vxe-column>
        <vxe-column field="groupName" title="所在分区" width="150" header-align="center"></vxe-column>
        <vxe-column field="logDate" title="统计日期" width="100" header-align="center">
          <template #default="{ row }">
            {{new Date(row.logDate).format('yyyy-MM-dd')}}
          </template>
        </vxe-column>
        <vxe-column field="readStartTime" title="起始时间" width="150" header-align="center"></vxe-column>
        <vxe-column field="readEndTime" title="截止时间" width="150" header-align="center"></vxe-column>
        <vxe-column field="readEnergy" title="能耗总值(KWh)" width="120" header-align="center"></vxe-column>
        <vxe-column field="beforeEnergy" title="改造前能耗(KWh)" width="140" header-align="center"></vxe-column>
        <vxe-column field="diffEnergy" title="能耗差值(KWh)" width="120" header-align="center"></vxe-column>
        <vxe-column field="diffRate" title="节能率(%)" width="100" header-align="center"></vxe-column>
        <vxe-column title="通道号" width="80" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">通道一</div>
              <div v-if="params.row.enable2" class="split-row-item">通道二</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="readStartEnergy1" title="能耗起始值(KWh)" width="140" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.readStartEnergy1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.readStartEnergy2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="readEndEnergy1" title="能耗结束值(KWh)" width="140" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.readEndEnergy1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.readEndEnergy2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="readEnergy1" title="当前能耗(KWh)" width="130" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.readEnergy1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.readEnergy2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="readStartOpenMins1" title="亮灯累计时长起始值(分)" width="180" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.readStartOpenMins1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.readStartOpenMins2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="readEndOpenMins1" title="亮灯累计时长结束值(A)" width="180" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.readEndOpenMins1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.readEndOpenMins2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="readOpenMins1" title="亮灯时长(分)" width="120" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.readOpenMins1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.readOpenMins2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="beforeEnergy1" title="改造前能耗(KWh)" width="140" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.beforeEnergy1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.beforeEnergy2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="beforePower1" title="改造前功率(W)" width="120" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.beforePower1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.beforePower2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="afterPower1" title="当前功率(W)" width="120" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.afterPower1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.afterPower2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="diffEnergy1" title="能耗差值" width="100" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.diffEnergy1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.diffEnergy2 }}</div>
            </div>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <div class="footer-data-area">
      <vxe-pager :layouts="pageLayouts" :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange"></vxe-pager>
    </div>
  </div>
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import GroupTree from '../../../common/treeGroup/Index'
export default {
  name: 'ConfigLampIndex',
  components: {
    GroupTree,
  },
  props: {
  },
  data() {
    return {
      curTab: 'hour24',
      list: [],
      rowHeight: 48,
      editItem: { isAdd: true, data: {} },
      showEditModal: false,
      showArgModal: false,
      showImportModalNew: false,
      showImportModalOld: false,
      exportLoading: false,
      dataRefresh: 0,
      filter: {
        groupId: 0,
        name: "",
        code: "",
        start: '',
        end: '',
        size: 20,//20 100 500 1000 5000
        index: 1,
      },
      total: 0,
      exportExcel: {
        data: [],
        isAll: false,
      },
      form: {
        name: '',
        areaId: 0,
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['pageLayouts', 'pageSizes']),
    ...mapState('group', ['groups', 'groupTreeSelectedNode']),
  },
  watch: {
    groupTreeSelectedNode(){
      this.search();
    },
  },
  mounted: function () {
    let now = new Date();
    let monthFirst = now.monthFirstDay();
    // monthFirst.setMonth(monthFirst.getMonth() - 1);
    this.filter.start = monthFirst.format('yyyy-MM-dd');
    let monthLast = monthFirst.monthLastDay();
    this.filter.end = monthLast.format('yyyy-MM-dd');
  },
  destroyed: function () {
  },
  methods: {
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.getList(true)
    },
    selectAllEvent({ checked }) {//全选
      this.exportExcel.isAll = checked;
      if (!checked) {
        this.exportExcel.data = [];
      }
    },
    checkRowKeys() {//默认选中
      let table = this.$refs.chkTable;
      this.exportExcel.data.forEach(el => {
        this.list.forEach((ele) => {
          if (ele.id == el.id) {
            table.setCheckboxRow(table.getRowById(ele.id), true);
          }
        });
      })
      this.dataRefresh++;
    },
    exportData: function () {
      this.filter.groupId = this.groupTreeSelectedNode.id;
      this.$store.dispatch('auth/reqFile', {
        title: '单灯配置数据.xlsx',
        url: `device/light/ExportNonePoleLightConfigs`,
        args: this.filter,
      });
    },
    selectChangeEvent({ rowIndex }) {
      this.$refs.chkTable.reloadRow(this.list, null, rowIndex);
      let leng = this.exportExcel.data.filter((el) => el.id == this.list[rowIndex].id)
      if (leng.length == 0 && this.list[rowIndex].checked) {
        this.exportExcel.data.push(this.list[rowIndex])
      }
      if(!this.list[rowIndex].checked && leng.length >0){
        this.exportExcel.data= this.exportExcel.data.filter(item=>item.id!==this.list[rowIndex].id)
      }
    },
    search: function(){
      this.filter.index = 1;
      this.getList();
    },
    getList: function () {
      this.filter.groupId = this.groupTreeSelectedNode.id;
      this.filter.start = new Date(this.filter.start).format('yyyy-MM-dd 00:00:00');
      this.filter.end = new Date(this.filter.end).format('yyyy-MM-dd 23:59:59');
      this.$axios.post(`device/energy/QueryLightLog`, this.filter).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data.list);
          this.$set(this, 'total', res.data.count);
          let muti = false;
          for(let item of res.data.list){
            if(item.enable1 && item.enable2){
              muti = true;
              break;
            }
          }
          this.rowHeight = muti ? 60 : 48;
        }
      });
    },
  }
}
</script>
<style scoped>
.config-device-container {
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  /* padding: 10px; */
  background-color: #F6F6FA;
  border-radius: 8px;
}
.config-tree-area{
  /* border: solid 1px #dcdee2; */
  width: 300px !important;
  flex: none;
  border-radius: 6px;
}
.device-data-area {
  margin-left: 10px;
  border: solid 1px #dcdee2;
  width: 300px;
  flex: auto;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  background: #fff;
}
.query-data-area {
  border-bottom: solid 1px #dcdee2;
  align-items: center;
  /* margin: 5px 0; */
  padding: 5px 0;
  height: 45px;
  flex: none;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  padding: 5px;
  /* padding-left: 10px; */
}
.station-alarm-tabs {
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab {
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
.split-row-area{
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  margin: 0 -10px;
}
.split-row-item{
  /* border-bottom: solid 1px red; */
  height: 30px;
  flex: none;
  line-height: 30px;
  text-align: center;
}
.split-row-flag{
  border-bottom: solid 1px #dcdee2;
}
</style>